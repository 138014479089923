#scannerContainer {
    min-height: 100%;
    position: absolute;

    #viewFinder {
        position: absolute;
        width: 30%;
        height: 0;
        padding-top: 30%;
        z-index: 999;
        top: 35%;
        left: 50%;
        
        &:before {top:0;left:0;border-width: 3px 0 0 3px}
        &:after {top:0;right:0;border-width: 3px 3px 0 0}
        &>:first-child:before {bottom:0;right:0;border-width: 0 3px 3px 0}
        &>:first-child:after {bottom:0;left:0;border-width: 0 0 3px 3px}

        &:before, &:after, &>:first-child:before, &>:first-child:after {
            position:absolute;
            width:20px; height: 20px;
            border-style:solid;
            content: ' ';
        }

        &.success:before, &.success:after, &.success>:first-child:before, &.success>:first-child:after {
            border-color:green;
        }

        &.waiting:before, &.waiting:after, &.waiting>:first-child:before, &.waiting>:first-child:after {
            border-color:yellow;
        }

        &.error:before, &.error:after, &.error>:first-child:before, &.error>:first-child:after {
            border-color:red;
        }

        &.scanning:before, &.scanning:after, &.scanning>:first-child:before, &.scanning>:first-child:after {
            border-color:blue;
        }
    }
}
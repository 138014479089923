@import "~bootstrap/scss/bootstrap";

* {
    box-sizing: border-box;
}

.text-prewrap {
    white-space: pre-wrap;
}

.form-floating > .form-select {
    -webkit-appearance: initial;
    line-height: normal;
}

.dropdown-toggle.no-arrow::after {
    display: none !important; 
}

// Session Modal
.session.modal-backdrop {
    z-index: 9999;
}

.session.modal {
    z-index: 9999;
}

.divider {
    border-bottom: 1px solid #4d4d4d;

    &.dashed {
        border-bottom-style: dashed;
    }

    &.dotted {
        border-bottom-style: dotted;
    }
}

pre.data-block {
    max-height: 250px;
    overflow: auto;
    background: white;
    padding: 10px 15px;
}

//pinned footer
html {
    position: relative;
    min-height: 100%;

    body {
        height: 100%;
        margin-bottom: 51px;

        .pinned-footer {
            position: absolute;
            z-index: 10;
            bottom: 0;
            width: 100%;
            height: 51px;
            line-height: 50px;
            font-size: 1rem;
        }
    }
}

#adminContainer {
    .cell {
        border: 1px solid #dddddd;
        border-radius: 5px;
        box-shadow: 0 0 10px #dddddd;
        height: 100%;
        overflow: hidden;

        .fs-huge {
            font-size: 5rem!important;
        }

        .fs-xhuge {
            font-size: 10rem!important;
            line-height: 10rem;
        }
    }
}

#employee-navbar {
    min-height: 76px;
}

.adminLogo {
    max-width: 30vw;
    max-height: 50px;
    height: auto;
    width: auto;
    margin-left: 5px;
    margin-right: 20px;
}

// dropdown sub menus
.dropdown-submenu-toggle {
    position: relative !important;
}

.dropdown-submenu {
    display: none !important;
    position: absolute !important;
    left: 100% !important;
    top: -7px !important;
    transform: none !important;
}
.dropdown-menu .dropdown-submenu-left {
    right: 100% !important;
    left: auto !important;
}
.dropdown-menu > .dropdown-submenu-toggle:hover > .dropdown-submenu {
    display: block !important;
}

// flush accordians
.accordion-flush .accordion-item {
    background-color: transparent;
    border: none;
}

.accordion-flush .accordion-item .accordion-header .accordion-button {
    background-color: transparent;
    padding: 10px 0.5rem;
    font-weight: 700;
    border-top: none;
    border-bottom: 1px solid #adadad;
}

.accordion-flush .accordion-item .accordion-button:not(.collapsed) {
    color: revert;
}

.accordion-flush .accordion-item .accordion-body {
    padding: 0.5rem;
}

// Max Height breakpoints
@each $breakpoint in map-keys($grid-breakpoints) {
    @include media-breakpoint-up($breakpoint) {
        $infix: breakpoint-infix($breakpoint, $grid-breakpoints);
    
        .mh#{$infix}-350px { max-height: 350px !important; overflow-y: auto; }
    }
}

// Border breakpoints
@each $breakpoint in map-keys($grid-breakpoints) {
    @include media-breakpoint-up($breakpoint) {
        $infix: breakpoint-infix($breakpoint, $grid-breakpoints);
    
        .border#{$infix}-top {      border-top: $border-width solid $border-color !important; }
        .border#{$infix}-right {    border-right: $border-width solid $border-color !important; }
        .border#{$infix}-bottom {   border-bottom: $border-width solid $border-color !important; }
        .border#{$infix}-left {     border-left: $border-width solid $border-color !important; }
    
        .border#{$infix}-top-0 {    border-top: 0 !important; }
        .border#{$infix}-right-0 {  border-right: 0 !important; }
        .border#{$infix}-bottom-0 { border-bottom: 0 !important; }
        .border#{$infix}-left-0 {   border-left: 0 !important; }
    
        .border#{$infix}-x {
            border-left: $border-width solid $border-color !important;
            border-right: $border-width solid $border-color !important;
        }
    
        .border#{$infix}-y {
            border-top: $border-width solid $border-color !important;
            border-bottom: $border-width solid $border-color !important;
        }
        // support of .border-sm (-md, -lg, -xl)
        .border#{$infix} {
            border-top: $border-width solid $border-color !important;
            border-bottom: $border-width solid $border-color !important;
            border-left: $border-width solid $border-color !important;
            border-right: $border-width solid $border-color !important;
        }
        .border#{$infix}-0 {
            border-top: 0 !important;
            border-bottom: 0 !important;
            border-left: 0 !important;
            border-right: 0 !important;
        }
    }
}